<template>
    <div>
        <!--系列活动、活动标签、弹窗-->
        <el-dialog
                :title="title"
                :visible.sync="seriesActivities"
                width="60%"
                :close-on-click-modal="tanchuan"
                :before-close="handleClose1">
            <el-row>
                <el-col :span="10" :offset="14">
                    <el-col :span="12">
                        <el-input size="small" v-model="search" placeholder="请输入内容"></el-input>
                    </el-col>
                    <el-col :span="6">
                        <el-button size="small" @click="popUpSearch" type="primary">搜索</el-button>
                    </el-col>
                    <el-col :span="6">
                        <el-button size="small" @click="popAdd" type="primary">新增</el-button>
                    </el-col>
                </el-col>
            </el-row>
            <div style="height: 60vh">
                <el-table
                        :data="dataList"
                        v-loading="loading1"
                        size="small"
                        height="calc(100% - 80px)"
                        class="table">
                    <el-table-column v-if="title == '系列活动管理'" prop="name" label="系列名称" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column v-if="title == '系列活动管理'" prop="count" label="归档活动数" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column v-if="title == '系列活动管理'" prop="seriesTimePeriod" label=系列时段 show-overflow-tooltip>
                        <template slot-scope="scope">{{ `${scope.row.startTime} 至 ${scope.row.endTime}` }}
                        </template>
                    </el-table-column>
                    <el-table-column v-if="title == '活动标签管理'" prop="name" label="活动标签" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column v-if="title == '系列活动管理'" fixed="right" width="200" label="操作">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" type="text"
                                       v-if="hasPermission('activity:releaseActivities:choice') && choice == 1"
                                       size="mini" @click="edit(scope.row,)">选择
                            </el-button>
                            <el-button icon="el-icon-edit" type="text"
                                       v-if="hasPermission('activity:releaseActivities:edit')"
                                       size="mini" @click="editSeries(scope.row,)">编辑
                            </el-button>
                            <el-button
                                    v-if="title == '系列活动管理' && hasPermission('activity:releaseActivities:detailedList')"
                                    icon="el-icon-tickets"
                                    type="text" size="mini" @click="activityList(scope.row)">活动清单
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="title == '活动标签管理'" fixed="right" width="400" label="操作">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" type="text"
                                       v-if="hasPermission('activity:releaseActivities:choice') && choice == 1"
                                       size="mini" @click="edit(scope.row,)">选择
                            </el-button>
                            <el-button icon="el-icon-edit" type="text"
                                       v-if="hasPermission('activity:releaseActivities:edit')"
                                       size="mini" @click="editSeries(scope.row,)">编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        :current-page="pageNo1"
                        :page-size="pageSize1"
                        :page-sizes="[10, 20, 50, 100]"
                        :total="total1"
                        @size-change="sizeChangeHandle1"
                        @current-change="currentChangeHandle1"
                        background
                        layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
        </el-dialog>

        <!--弹窗新增-->
        <el-dialog
                :title="labelTitle"
                :visible.sync="addacivityLabel"
                width="40%"
                :close-on-click-modal="tanchuan"
                :before-close="handleClose">
            <el-form :model="ruleForm"  :rules="rules" ref="dynamicValidateForm" label-width="100px">
                <div v-if="labelTitle =='活动标签新增' ||  labelTitle =='活动标签编辑'">
                    <el-row>
                        <el-col :span="15">
                            <el-form-item label="活动标签" prop="activitybiaoqian">
                                <el-input size="small" v-model="ruleForm.activitybiaoqian" maxlength="23"
                                          placeholder="请输入内容"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div v-else>
                    <el-row>
                        <el-col :span="15">
                            <el-form-item label="系列名称" prop="seriesName">
                                <el-input size="small" maxlength="23" v-model="ruleForm.seriesName" placeholder="请输入内容"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-form-item label="系列时段" required>
                            <el-col :span="11">
                                <el-form-item prop="seriesStart">
                                    <el-date-picker
                                            style="width: 100%"
                                            size="small"
                                            v-model="ruleForm.seriesStart"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            format="yyyy-MM-dd HH:mm:ss"
                                            type="datetime"
                                            placeholder="开始时间">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" :offset="1">
                                <el-form-item prop="seriesEnd">
                                    <el-date-picker
                                            style="width: 100%"
                                            size="small"
                                            v-model="ruleForm.seriesEnd"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            format="yyyy-MM-dd HH:mm:ss"
                                            type="datetime"
                                            placeholder="结束时间">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                    </el-row>
                </div>
                <el-row>
                    <el-col :span="6" :offset="9">
                        <el-button @click="resetForm('dynamicValidateForm')">取 消</el-button>
                    </el-col>
                    <el-col :span="6" :offset="1">
                        <el-button type="primary" @click="submitForm('dynamicValidateForm')">确 定</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

        <!--活动清单弹窗-->
        <el-dialog
                title="活动清单"
                :visible.sync="activityListPopup"
                width="50%"
                :close-on-click-modal="tanchuan"
                :before-close="handleClosePopup">
            <el-row>
                <el-col :span="6" :offset="13">
                    <el-input style="width: 100%" size="small" v-model="activityName" placeholder="请输入活动名称"></el-input>
                </el-col>
                <el-col :span="4" :offset="1">
                    <el-button @click="auygfyuyugfayud" size="small" type="primary">搜索</el-button>
                </el-col>
            </el-row>


            <el-table
                    :data="activityList2"
                    v-loading="activityloading"
                    size="small"
                    height="400px"
                    class="table">
                <el-table-column prop="name" label="活动名称" width="200">
                </el-table-column>
                <el-table-column prop="actiState" label="状态" width="100">
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("acti_state",scope.row.actiState,'-')}}
                    </template>
                </el-table-column>
<!--                <el-table-column prop="actiServiceObj" label="类型" width="100">-->
<!--                    <template slot-scope="scope">-->
<!--                        {{$dictUtils.getDictLabel("acti_type", scope.row.actiServiceObj ,'-')}}-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column prop="startTime" label="活动时间" width="300">
                    <template slot-scope="scope">{{ `${scope.row.startTime} - ${scope.row.endTime}` }}</template>
                </el-table-column>
            </el-table>
            <el-pagination
                    :current-page="activitypageNo"
                    :page-size="activitypageSize"
                    :page-sizes="[10, 20, 50, 100]"
                    :total="activitytotal"
                    @size-change="activitysizeChangeHandle"
                    @current-change="activitycurrentChangeHandle"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "seriesLabelPop",
        props: {
            seriesActivities: Boolean,
            series: Number,
            choice: Number, //0就是从首页经，没有选择操作；1就是发布活动进，有选择操作
        },
        data() {
            return {
                detailedListId:'',//系列id
                tanchuan: false,
                title: '', //弹窗标题
                pageNo1: 1,
                pageSize1: 10,
                total1: 0,
                seriesActivityId: "",//系列活动id
                search: '', //弹窗搜索
                addacivityLabel: false,
                dataList: [],
                loading1: false, //弹窗表的加载
                activityListPopup: false, //活动清单弹窗
                activityName: '',//活动名称
                activityList2: [],//活动清单
                activityloading: false,//货送清单表的加载
                activitypageNo: 1,
                activitypageSize: 10,
                labelTitle: '', //新增的标题
                activitytotal: 0,
                ruleForm: {
                    activitybiaoqian: '',//标签名
                    seriesName: '',//系列名称
                    seriesStart: '',//系列开始时间
                    seriesEnd: '',//系列结束时间
                },
                rules: {
                    activitybiaoqian: [{
                        required: true, message: '请输入标签名', trigger: 'blur'
                    }],
                    seriesName: [{
                        required: true, message: '请输入系列名', trigger: 'blur'
                    }],
                    seriesStart: [{
                        required: true, message: '请输入系列开始时间', trigger: 'blur'
                    }],
                    seriesEnd: [{
                        required: true, message: '请输入系列结束时间', trigger: 'blur'
                    }],
                },
            };
        },
        watch: {
            series() {
                if (this.series == 1) {
                    this.title = '系列活动管理';
                    this.listRequest();
                } else {
                    this.title = '活动标签管理';
                    this.tagListRequest();
                }
            }
        },
        mounted() {
            console.log(this.seriesActivities);
        },
        methods: {
            //弹窗新增
            popAdd() {
                this.seriesActivityId = '';
                if (this.title == '系列活动管理') {
                    this.addacivityLabel = true;
                    this.labelTitle = "系列活动新增";
                } else {
                    //活动标签的新增
                    this.addacivityLabel = true;
                    this.labelTitle = "活动标签新增";

                }
            },

            // 弹窗每页数
            sizeChangeHandle1(val) {
                this.pageSize1 = val
                this.pageNo1 = 1
                if (this.title == '系列活动管理') {
                    this.listRequest();
                } else {
                    this.tagListRequest();
                }
            },

            // 弹窗当前页
            currentChangeHandle1(val) {
                this.pageNo1 = val
                if (this.title == '系列活动管理') {
                    this.listRequest();
                } else {
                    this.tagListRequest();
                }
            },

            //活动清单每页数
            activitysizeChangeHandle(val) {
                this.activitypageSize = val
                this.activitypageNo = 1
                this.activityList();
            },

            //活动清单当前页
            activitycurrentChangeHandle(val) {
                this.activitypageNo = val
                this.activityList();
            },

            //弹窗新增遮罩层关闭
            handleClose(done) {
                this.resetForm('dynamicValidateForm')
            },

            //系列活动、标签弹窗关闭
            handleClose1(done) {
                this.search = '';
                this.$emit('closed');
                // this.seriesActivities = false;
            },

            //重置
            resetForm(formName) {
                this.$refs[formName].resetFields()
                this.addacivityLabel = false;
            },

            //清除弹窗输入框内容
            eliminate() {
                this.seriesActivityId = '';
                this.ruleForm.seriesName = '';
                this.ruleForm.seriesStart = '';
                this.ruleForm.seriesEnd = '';
                this.ruleForm.activitybiaoqian = '';
            },

            //弹窗搜索
            popUpSearch() {
                    this.pageNo1 = 1;
                    this.pageSize1 = 10;
                    this.total1 = 0;
                if (this.title == '系列活动管理') {
                    this.listRequest();
                } else {
                    //活动标签的搜索
                    this.tagListRequest();
                }
            },

            //新增的确定按钮
            submitForm(formName) {
                console.log(formName)
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.addacivityLabel = false;
                        if (this.labelTitle == '系列活动编辑' || this.labelTitle == '系列活动新增') {  //系列
                            if (this.seriesActivityId != '') { //编辑数据
                                this.$axios(this.api.activity.modifySeries, {
                                    id: this.seriesActivityId,
                                    name: this.ruleForm.seriesName,
                                    startTime: this.ruleForm.seriesStart,
                                    endTime: this.ruleForm.seriesEnd
                                }, 'put').then((res) => {
                                    if (res.status) {
                                        this.$message.success(res.msg);
                                        this.eliminate();
                                        this.listRequest();
                                    }else {
                                        this.$message.error(res.msg);
                                    }
                                })
                            } else {  //新增数据
                                this.$axios(this.api.activity.addSeries, {
                                    name: this.ruleForm.seriesName,
                                    startTime: this.ruleForm.seriesStart,
                                    endTime: this.ruleForm.seriesEnd
                                }, 'post').then((res) => {
                                    if (res.status) {
                                        this.$message.success(res.msg);
                                        this.eliminate();
                                        this.listRequest();
                                    }else {
                                        this.$message.error(res.msg);
                                    }
                                })
                            }
                        } else {  //标签
                            if (this.seriesActivityId != '') { //编辑数据
                                this.$axios(this.api.activity.modifyLabel, {
                                    id: this.seriesActivityId,
                                    name: this.ruleForm.activitybiaoqian,
                                }, 'put').then((res) => {
                                    if (res.status) {
                                        this.$message.success(res.msg);
                                        this.eliminate();
                                        this.tagListRequest();
                                    }else {
                                        this.$message.error(res.msg);
                                    }
                                })
                            } else {  //新增数据
                                this.$axios(this.api.activity.newLabel, {
                                    name: this.ruleForm.activitybiaoqian,
                                }, 'post').then((res) => {
                                    if (res.status) {
                                        this.$message.success(res.msg);
                                        this.eliminate();
                                        this.tagListRequest();
                                    }else {
                                        this.$message.error(res.msg);
                                    }
                                })
                            }
                        }
                        this.resetForm('dynamicValidateForm')
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //编辑
            editSeries(obj) {
                console.log(1)
                this.addacivityLabel = true;
                if (this.title == '系列活动管理') {
                    this.labelTitle = "系列活动编辑";
                    this.$axios(this.api.activity.idGetSeriesInformation + obj.id, {}, 'get').then((res) => {
                        console.log(res)
                        if (res.status) {
                            this.seriesActivityId = res.data.id;
                            this.ruleForm.seriesName = res.data.name;
                            this.ruleForm.seriesStart = res.data.startTime;
                            this.ruleForm.seriesEnd = res.data.endTime;
                        }
                    })
                } else {
                    this.labelTitle = '活动标签编辑'
                    this.$axios(this.api.activity.idGetTag + obj.id, {}, 'get').then((res) => {
                        if (res.status) {
                            this.ruleForm.activitybiaoqian = res.data.name;
                            this.seriesActivityId = res.data.id;
                        }
                    })
                }
            },
            //活动系列列表请求
            listRequest() {
                this.loading1 = true;
                this.$axios(this.api.activity.seriesList, {
                    current: this.pageNo1,
                    size: this.pageSize1,
                    name: this.search,
                }, 'post').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records;
                        this.total1 = parseInt(res.data.total);
                        this.loading1 = false;
                    }
                })
            },

            //标签列表请求
            tagListRequest() {
                this.loading1 = true;
                this.$axios(this.api.activity.tagListRequest, {
                    current: this.pageNo1,
                    size: this.pageSize1,
                    name: this.search,
                }, 'post').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records;
                        this.total1 = parseInt(res.data.total);
                        this.loading1 = false;
                    }
                })
            },

            //选择
            edit(obj) {
                this.search = '';
                this.$emit('closed');
                this.$emit('select', obj)
            },

            //搜索
            auygfyuyugfayud(){
                this.activitypageNo = 1;
                this.activitypageSize = 10;
                this.activitytotal = 0;
                this.activityListsearch();
            },

            //
            activityListsearch(){
                this.activityloading = true;
                this.$axios(this.api.activity.listSeriesActivities, {
                    current: this.activitypageNo,
                    seriesId: this.detailedListId,
                    activityName: this.activityName,
                    size: this.activitypageSize,
                }, 'post').then((res) => {
                    if (res.status) {
                        this.activityList2 = res.data.records;
                        this.activityloading = false;
                        this.activitytotal = parseInt(res.data.total);
                    }
                })
            },

            //活动清单按钮
            activityList(row) {
                this.detailedListId = row.id;
                this.activityList2 = [];
                this.activityloading = true;
                this.$axios(this.api.activity.listSeriesActivities, {
                    current: this.activitypageNo,
                    seriesId: this.detailedListId,
                    activityName: this.activityName,
                    size: this.activitypageSize,
                }, 'post').then((res) => {
                    if (res.status) {
                        this.activityList2 = res.data.records;
                        this.activityloading = false;
                        this.activitytotal = parseInt(res.data.total);
                    }
                })
                this.activityListPopup = true;
            },

            //活动清单遮罩层关闭
            handleClosePopup(done) {
                this.activityName = '';
                this.activityListPopup = false;
            },

        },
    }
</script>

<style scoped>
</style>